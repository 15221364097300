// import * as React from 'react';
// import { useEffect, useRef, useState, useCallback } from 'react';
import { useState } from 'react';

// import Link from 'next/link'

import styles from 'styles/components/form.module.scss';

//snack bar
import React from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import InputAdornment from '@mui/material/InputAdornment';
// import Button from '@mui/material/Button';
// import PersonIcon from '@mui/icons-material/Person';
// import LockIcon from '@mui/icons-material/Lock';

import { isMobileOnly } from "react-device-detect";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import Textfield from "components/FormsUI/Textfield";
import Select from "components/FormsUI/Select/Select";
import ButtonSubmit from "components/FormsUI/Button";

import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';

import { useRouter } from 'next/router'
// import { useCookies } from 'react-cookie';
// import axios from 'axios';

import { useTranslation } from 'react-i18next';

function FormComponent({collection}){
  const {t} =  useTranslation();
	const isMobile = isMobileOnly;
	const router = useRouter()
  const { locale } = router;

	const INITIAL_FORM_STATE = {
    collection:collection,
		name: '',
    email: '',
    country: '',
    message: '',
	};

	const FORM_VALIDATION = Yup.object().shape({
    name: Yup
      .string(t('common:full_name_s'))
      .required(t('common:full_name_r')),
    email: Yup
      .string(t('common:email_s'))
      .required(t('common:email_r'))
      .email(t('common:email_v')),
    country: Yup
      .string(t('common:country_s'))
      .required(t('common:country_r')),
    message: Yup
      .string(t('common:message_s'))
      .required(t('common:message_r')),
	});

  const [showMessage, setShowMessage] = useState(false);
  const [formValid, setformValid] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowMessage(false);
  };

  const handleSubmit = async (values,{resetForm}) => {
    // console.log(values)
    // e.preventDefault();
    // let isValidForm = handleValidation();

    const res = await fetch("/api/sendgrid", {
      body: JSON.stringify(values),
      method: "POST",
    });

    const { error } = await res.json();
    if (error) {
       console.log(error);
       setformValid(false);
      return;
    }
    setShowMessage(true)
    setformValid(true);
    resetForm({});
  };

  return(
    <>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={handleSubmit}
      >
        <Form className={styles.form}>
          <div className={styles.fields_wr}>
            <div className={styles.field_wr}>
              <Textfield fullWidth name="name" label={t('common:full_name')} placeholder={t('common:full_name_p')} variant="filled" 
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineRoundedIcon className={styles.icon_wr}/>
                    </InputAdornment>
                )}}/>
            </div>
            <div className={styles.field_wr}>
              <Textfield fullWidth name="email" label={t('common:email')} placeholder={t('common:email_p')} variant="filled" 
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon className={styles.icon_wr}/>
                    </InputAdornment>
                )}}/>
            </div>
            <div className={styles.field_wr}>
              <Select name="country" label={t('common:country')} placeholder={t('common:country_p')} variant="filled" 
                options={
                  t('common:country_val', {returnObjects: true})
                } 
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PublicOutlinedIcon className={styles.icon_wr}/>
                    </InputAdornment>
                )}}/>
            </div>
            <div className={styles.field_wr}>
            <Textfield className={styles.textarea} name="message" label={t('common:message')} placeholder={t('common:message_p')} variant="filled" multiline rows={3} 
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={styles.icon_wr}>
                      <DraftsOutlinedIcon />
                    </InputAdornment>
                )}}/>
            </div>
            <div className={styles.field_wr}>
              <ButtonSubmit variant="outlined" className={`${styles.btn_min} btn_min`}>{t('common:btn_send')}</ButtonSubmit>
            </div>
          </div>
          
        </Form>
      </Formik>

      <Snackbar
        className={styles.snack_bar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={`bottom,center`}
        open={showMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
     <div>
          {formValid==true && 
            <Alert severity="success" variant="filled" sx={{display:"flex",alignItems:"center"}}>{t('common:message_sent')}</Alert>
          }
          {formValid==false && 
            <Alert severity="error" variant="filled" sx={{display:"flex",alignItems:"center"}}>{t('common:message_fail')}</Alert>
          }
        </div>
      </Snackbar>                
    </>
  )
}
export default FormComponent;